import {
  Badge,
  Box,
  Button,
  Heading,
  Icon,
  Table,
  Tbody,
  Td,
  Text,
  Thead,
  Tr,
} from '@chakra-ui/react'
import {
  CheckIcon,
  CloseIcon,
  DeleteIcon,
  EditIcon,
  EmailIcon,
  LockIcon,
  ViewIcon,
} from '@chakra-ui/icons'
import React, { useEffect, useState } from 'react'
import { UserDataField, UserDataShow, UsersTableProps } from './_types'
import {
  activateUserRequest,
  deleteUserFromDbRequest,
  deleteUserRequest,
  verificationMailRequest,
} from '../../../../state/modules/abm/actions'
import { useDispatch, useSelector } from 'react-redux'

import ActionPopover from '../../../actionPopover'
import Loader from '../../../loader'
import Paginator from '../../../Paginator'
import { userTableStyles } from './styles'
import { usersFields } from '../../../../utils/abm'

const UsersTable: React.FC<UsersTableProps> = ({
  showModal,
  currentPage,
  setCurrentPage,
  setCurrentUser,
  searchFilter,
}) => {
  const [users, setUsers] = useState<any>(null)
  const abm = useSelector((state: any) => state?.abm)
  const dispatch = useDispatch()

  useEffect(() => {
    abm?.usersInformation && setUsers(abm.usersInformation?.rows)
  }, [abm?.usersInformation, currentPage])

  const showedFields = usersFields.filter((e) => e.isShown).sort((e1, e2) => e1.order - e2.order)

  const handleClosePopover = (id: string) => {
    const button = document.getElementById(`PopoverCloseButton${id}`)
    button?.click()
  }
  const handleDelete = (id: string) => {
    const token = localStorage.getItem('access_token') ?? ''
    dispatch(deleteUserRequest({ userId: id, token }))
    handleClosePopover(id)
  }

  const handleActivate = (id: string) => {
    const token = localStorage.getItem('access_token') ?? ''
    dispatch(activateUserRequest({ userId: id, token }))
    handleClosePopover(id)
  }

  const handleDeleteUser = (id: string) => {
    const userId = id.split('_')[0]
    const token = localStorage.getItem('access_token') ?? ''
    dispatch(deleteUserFromDbRequest({ userId, token }))
    handleClosePopover(id)
  }

  const handleSendVerificationEmail = (id: string) => {
    const userId = id.split('_')[0]

    const token = localStorage.getItem('access_token') ?? ''
    dispatch(verificationMailRequest({ userId, token }))
    handleClosePopover(id)
  }

  return (
    <Box sx={userTableStyles.box}>
      {!users ? (
        <Loader props={userTableStyles.loading} />
      ) : users.length === 0 ? (
        searchFilter !== null ? (
          <Box sx={userTableStyles.noResultsContainer}>
            <Text sx={userTableStyles.noResults}>
              *No existen registros acordes a los filtros solicitados
            </Text>
          </Box>
        ) : (
          <Text sx={userTableStyles.noResults}>*No existen registros</Text>
        )
      ) : (
        <>
          <Box>
            <Table sx={userTableStyles.table}>
              <Thead>
                <Tr>
                  {showedFields.map((e: UserDataField) => {
                    return (
                      <Td maxWidth={`${e.width}%` || 'auto'} key={e.order}>
                        <Text sx={userTableStyles.tableHead}>{e.name}</Text>
                      </Td>
                    )
                  })}
                  <Td sx={userTableStyles.tableHead}>Acción</Td>
                </Tr>
              </Thead>

              <Tbody>
                {users.map((e: any) => (
                  <Tr key={e.id}>
                    <Td sx={userTableStyles.tableData} width={`${showedFields[0]?.width}%`}>
                      {e.Profile.lastName || '-'}
                    </Td>
                    <Td sx={userTableStyles.tableData} width={`${showedFields[1]?.width}%`}>
                      {e.Profile.name || '-'}
                    </Td>
                    <Td sx={userTableStyles.tableData} width={`${showedFields[2]?.width}%`}>
                      {e.Profile.dni || '-'}
                    </Td>
                    <Td sx={userTableStyles.tableData} width={`${showedFields[3]?.width}%`}>
                      {e.email || '-'}
                    </Td>
                    <Td sx={userTableStyles.tableData} width={`${showedFields[4]?.width}%`}>
                      {e.verified === false ? (
                        <Badge sx={userTableStyles.badge} colorScheme='yellow'>
                          Sin verificar
                        </Badge>
                      ) : e.discardedAt === null ? (
                        <Badge sx={userTableStyles.badge} colorScheme='green'>
                          Activo
                        </Badge>
                      ) : (
                        <Badge sx={userTableStyles.badge} colorScheme='purple'>
                          Inactivo
                        </Badge>
                      )}
                    </Td>
                    <Td sx={userTableStyles.tableData}>
                      <Box sx={userTableStyles.actionBox}>
                        <Icon
                          as={ViewIcon}
                          cursor='pointer'
                          boxSize={4}
                          color={'#2980B9'}
                          onClick={() => {
                            showModal('3')
                            setCurrentUser(e)
                          }}
                        />
                        <Icon
                          as={EditIcon}
                          cursor='pointer'
                          boxSize={4}
                          color={'#2980B9'}
                          onClick={() => {
                            showModal('2')
                            setCurrentUser(e)
                          }}
                        />
                        {e.verified === true ? (
                          <ActionPopover
                            handleAction={e.discardedAt !== null ? handleActivate : handleDelete}
                            id={e.id}
                            title={e.discardedAt !== null ? '¿Activar?' : '¿Desactivar?'}
                            handleClose={handleClosePopover}
                            buttonSize='12px'
                          >
                            {e.discardedAt !== null ? (
                              <Icon as={CheckIcon} cursor='pointer' boxSize={4} color={'#2980B9'} />
                            ) : (
                              <Icon as={CloseIcon} cursor='pointer' boxSize={3} color={'#2980B9'} />
                            )}
                          </ActionPopover>
                        ) : (
                          <>
                            <ActionPopover
                              handleAction={handleDeleteUser}
                              id={`${e.id}_delete`}
                              title={'¿Eliminar?'}
                              handleClose={handleClosePopover}
                              buttonSize='12px'
                            >
                              <Icon
                                as={DeleteIcon}
                                cursor='pointer'
                                boxSize={4}
                                color={'#2980B9'}
                              />
                            </ActionPopover>
                            <ActionPopover
                              handleAction={handleSendVerificationEmail}
                              id={`${e.id}_email`}
                              title={'¿Enviar email de verificación?'}
                              handleClose={handleClosePopover}
                              buttonSize='12px'
                            >
                              <Icon as={EmailIcon} cursor='pointer' boxSize={4} color={'#2980B9'} />
                            </ActionPopover>
                          </>
                        )}
                      </Box>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
          <Paginator
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            elements={abm?.usersInformation?.rows}
            elementsPerpage={10}
            elementsLength={abm?.usersInformation?.count}
          />
        </>
      )}
    </Box>
  )
}

export default UsersTable
